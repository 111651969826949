import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTheme } from '@material-ui/core/styles';
import logo from '../../img/AMIG-Logo_White_Horizonal.svg';

import {
  List,
  ListItem,
  ListItemText,
  Link,
  Container,
  Box,
  Typography,
  Grid,
  IconButton,
  SvgIcon,
} from '@material-ui/core';

const Footer = () => {
  const links = [
    {
      title: 'Policyholder services',
      links: [
        { label: 'Make a payment', href: 'https://blog.amig.com/customer/make-a-payment/' },
        { label: 'Enroll in EZPay', href: 'https://mypolicy.amig.com/mypolicy/' },
        { label: 'File a claim', href: 'https://blog.amig.com/customer/file-a-claim/' },
        { label: 'Track a claim', href: '#' },
      ]
    },
    {
      title: 'Contact us',
      links: [
        { label: 'Chat', href: 'https://policyholders.amig.com/' },
        { label: 'Give us a call', href: 'https://blog.amig.com/company/contact-us/ ' },
        { label: 'Send us an email', href: '#' },
      ]
    }
  ];
  const legalLinks = [
    { label: 'Internet privacy', href: 'https://blog.amig.com/company/internet-privacy-policy/' },
    { label: 'Terms of use', href: 'https://blog.amig.com/company/legal/legal-terms/' },
    { label: 'Information practices', href: 'https://blog.amig.com/company/legal/privacy-notice/' },
  ];
  const social = [
    { label: 'social-facebook', 
      href: 'https://www.facebook.com/AmericanModern/',
      path: 'M4.667 9.333v5.334h4V24H14v-9.333h4l1.333-5.334H14V6.667a1.333 1.333 0 0 1 1.333-1.334h4V0h-4a6.665 6.665 0 0 0-6.666 6.667v2.666h-4z' },
    { label: 'instagram', 
      href: 'https://www.instagram.com/americanmodins/',
      path: 'M12 0C8.741 0 8.332.014 7.053.072 5.775.13 4.903.333 4.14.63a5.881 5.881 0 0 0-2.126 1.384A5.881 5.881 0 0 0 .63 4.14c-.297.763-.5 1.635-.558 2.912C.014 8.332 0 8.741 0 12c0 3.259.014 3.668.072 4.948.058 1.277.261 2.149.558 2.912.307.79 2.72 3.203 3.51 3.51.763.297 1.635.5 2.913.558C8.332 23.986 8.741 24 12 24c3.259 0 3.667-.014 4.947-.072 1.278-.058 2.15-.261 2.913-.558a5.881 5.881 0 0 0 2.126-1.384 5.881 5.881 0 0 0 1.384-2.126c.297-.763.5-1.635.558-2.912.058-1.28.072-1.689.072-4.948 0-3.259-.014-3.668-.072-4.948-.058-1.277-.261-2.149-.558-2.912a5.881 5.881 0 0 0-1.384-2.126A5.881 5.881 0 0 0 19.86.63c-.763-.297-1.635-.5-2.913-.558C15.667.014 15.259 0 12 0zm0 16.733a4.733 4.733 0 1 1 0-9.466 4.733 4.733 0 0 1 0 9.466zm8.504-11.139a2.098 2.098 0 1 1-4.196.002 2.098 2.098 0 0 1 4.196-.002z' },
    { label: 'twitter', 
      href: 'https://twitter.com/americanmodins',
      path: 'M24 2.514c-1.2.588-2.376.827-3.6 1.188-1.345-1.518-3.34-1.602-5.256-.884-1.916.718-3.172 2.472-3.144 4.484v1.2c-3.894.1-7.362-1.674-9.6-4.8 0 0-5.018 8.92 4.8 13.2-2.246 1.497-4.487 2.506-7.2 2.4 3.97 2.164 8.296 2.908 12.041 1.821 4.296-1.248 7.826-4.468 9.181-9.291.404-1.466.605-2.982.596-4.503C21.816 7.03 23.63 4.002 24 2.513v.001z' },
  ];
  const { palette, breakpoints } = useTheme();
  const tabletBreakpoint = breakpoints.up(600);
  const useStyles = makeStyles((theme) => ({
    footerContainer: {
      display: "flex",
      flexDirection: "column",
      maxWidth: 1300,
      margin: '0 auto',
      [tabletBreakpoint]: {
        flexDirection: "row",
      }
    },
    footerLeftContainer: {
      width: "100%",
      [tabletBreakpoint]: {
        width: "clamp(1px, 100%, 320px)",
      }
    },
    footerRightContainer: {
      width: "100%",
      display: "grid",
      gridTemplateColumns: "1fr", 
      gridTemplateRows: "auto",
      gridTemplateAreas: `"footerLinks0"
      "footerLinks1"
      "AMIGFooterSpacing"
      "AMIGFooterTerms"
      "AMIGFooterLegal"
      "AMIGFooterCopyright"`, 
      gap: ".5rem",
      [tabletBreakpoint]: {
        width: "clamp(1px, 100%, 1000px)",
        gridTemplateColumns: "1fr 1fr 1fr 1fr",
        gridTemplateAreas: `"footerLinks0 footerLinks1 . ."
        "AMIGFooterSpacing AMIGFooterSpacing AMIGFooterSpacing AMIGFooterSpacing"
        "AMIGFooterTerms AMIGFooterTerms AMIGFooterTerms AMIGFooterTerms"
        "AMIGFooterLegal AMIGFooterLegal AMIGFooterCopyright AMIGFooterCopyright"`, 
      },
      "& *[class^=footerLinks]": {
        margin: 0,
        padding: "1rem",
      }
    },
    logoContainer: {
      margin: "0 0 1rem",
      [tabletBreakpoint]: {
        margin: "0px 0px 70px",
      }
    },
    logo: {
      width: 'clamp(1px, 100%, 170px)',
    },
    social: {
      margin: "0px .75rem 0 0",
      [tabletBreakpoint]: {
        margin: 0,
      },
      '& .MuiSvgIcon-root': {
        fill: 'transparent',
        stroke: '#F5F8FA',
        strokeWidth: 1.5,
        strokeLinejoin: 'round',
        strokeLinecap: 'round',
      },
      '& .MuiSvgIcon-root path': {
        width: '100%',
        aspectRatio: '1 / 1',
      }
    },
    listItem: {
      padding: '0px',
    },
    listText: {
      color: '#D6E4EB',
      '& .MuiTypography-body1': {
        fontSize: '16px',
        fontFamily: 'Arial',
      },
    },
    listTextHeader: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: 'Arial, sans-serif',
      },
    terms: {
      fontFamily: 'Arial, sans-serif',
      fontSize: '14px',
      color: '#D6E4EB',
      padding: "0 1rem",
      gridArea: "AMIGFooterTerms",
      [tabletBreakpoint]: {
        padding: "0 1rem",
      },
      "& p": {
        marginTop: 0,
      }
    },
    copyright: {
      fontFamily: 'Arial, sans-serif',
      fontSize: '14px',
      textAlign: "center",
      padding: "1rem 1rem 0",
      gridArea: "AMIGFooterCopyright",
      [tabletBreakpoint]: {
        textAlign: 'right',
      }
    },
    footer: {
      color: palette.primary.contrastText,
      backgroundColor: palette.primary.main,
      padding: '4rem 0',
      "& a": {
        color: `${palette.primary.contrastText} !important`,
      }
    },
    spacingFooter: {
      borderTop: '2px solid #5A91AF',
      padding: "1rem 0 0",
      gridArea: "AMIGFooterSpacing",
      [tabletBreakpoint]: {
        padding: '20px 0',
      }
    },
    legalLinks: {
      padding: "0 1rem",
      textAlign: 'center',
      gridArea: "AMIGFooterLegal",
      [tabletBreakpoint]: {
        padding: "1rem 1rem 0",
        textAlign: 'left',
      },
    },
    legalLink: {
      padding: '0 8px',
      borderRight: `1px solid ${palette.primary.contrastText}`,
      '&:last-child': {
        paddingRight: 0,
        border: '0 none'
      },
      '&:first-child': {
        paddingLeft: 0,
      },
    }
  }));

  const ListItemLink = props => (
    <ListItem component="a" {...props}>
      <ListItemText className={classes.listText} primary={props.label} />
    </ListItem>
  );

  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      <Box display="flex" className={classes.footerContainer}>
        <Container className={classes.footerLeftContainer}>
          <figure className={classes.logoContainer}>
            <img className={classes.logo} src={logo} alt='American Modern logo' />
          </figure>
          {social.map((socialIcon, index) => {
            return (
              <IconButton className={classes.social} aria-label={socialIcon.label} key={index} href={socialIcon.href}>
                <SvgIcon>
                  <path d={socialIcon.path} />
                </SvgIcon>
              </IconButton>
            );
          })}
        </Container>
        <section
          className={classes.footerRightContainer}
        >
          {links.map((linkGroup, index) => {
            return (
              <menu className={`footerLinks${index}`} key={index}>
                <Typography variant="h6" className={classes.listTextHeader}>
                  {linkGroup.title}
                </Typography>
                <List className={classes.list}>
                  {linkGroup.links.map((link, iterator) => {
                    return (
                      <ListItemLink key={`${index}-${iterator}`} className={classes.listItem} href={link.href} label={link.label} />
                    )
                  })}
                </List>
              </menu>
            );
          })}
          <Grid item xs={12} className={classes.spacingFooter} ></Grid>
          <section className={classes.terms}>
            <p>
              Coverage is subject to policy terms, conditions, limitations, exclusions, underwriting review and approval, and may vary or not be available for all risks or in all states. Rates and discounts vary, are determined by many factors, and are subject to change. Policies are written by one of the licensed insurers of American Modern Insurance Group, Inc., including but not limited to American Modern Property and Casualty Insurance Company (CA Lic. No. 6129-1).
            </p>
            <p>
              American Modern Insurance Group, American Modern, AMsuite, modernLINK, and Homeowners FLEX are registered trademarks of American Modern Insurance Group, Inc. All other names, logos, and brands described herein are trademarks or registered trademarks of their respective holders, and use herein does not imply affiliation with or sponsorship or endorsement by them of American Modern, its products, or its services.
            </p>
          </section>
          <Grid item xs={12} className={classes.legalLinks}>
            {legalLinks.map((link, index) => {
              return (
                <Typography variant="body2" component="span" className={classes.legalLink} key={index}>
                  <Link href={link.href} target="_blank" rel="noopener">
                    {link.label}
                  </Link>
                </Typography>
              )
            })}
          </Grid>
          <Grid item xs={12} className={classes.copyright}>©2022. American Modern Insurance Group, Inc. All rights reserved.</Grid>
        </section>
      </Box>
    </footer>
  );
};

export default Footer;
