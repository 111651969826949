export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const SHOW_LOADING = 'SHOW_LOADING';
export const HIDE_LOADING = 'HIDE_LOADING';

export const UPDATE_ACTIVE_STEP = 'UPDATE_ACTIVE_STEP';

// Page Navigation (Stepper)
export const UPDATE_PAGE_NAVIGATION = 'UPDATE_PAGE_NAVIGATION';
export const SET_ACTIVE_STEP = 'SET_ACTIVE_STEP';
export const SET_COMPLETED_STEP = 'SET_COMPLETED_STEP';
export const UPDATE_COMPLETED_STEPS = 'UPDATE_COMPLETED_STEPS';
export const UPDATE_STEP_CONTENT_INDEX = 'UPDATE_STEP_CONTENT_INDEX';

// Network Monitoring
export const NETWORK_CALL_SUCCEEDED = 'NETWORK_CALL_SUCCEEDED';
export const NETWORK_CALL_FAILED = 'NETWORK_CALL_FAILED';

// HTTP Methods
export const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  DELETE: 'DELETE',
};

// AgentInfo
export const UPDATE_PERSONAL_INFO = 'UPDATE_PERSONAL_INFO';
export const CLEAR_PERSONAL_INFO = 'CLEAR_PERSONAL_INFO';

// ApplicantInfo
export const UPDATE_APPLICANT_INFO = 'DATE_APPLICANT_INFO';
export const CLEAR_APPLICANT_INFO = 'CLEAR_APPLICANT_INFO';

// ATTACHMENTS
export const RETRIEVE_ATTACHMENTS = 'RETRIEVE_ATTACHMENTS';
export const UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT';
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';

// Policy Name Change
export const POLICY_NAME_CHANGE_SUCCESS = 'POLICY_NAME_CHANGE_SUCCESS';
export const POLICY_NAME_CHANGE_FAILURE = 'POLICY_NAME_CHANGE_FAILURE';

// Policy Summary Retrieve
export const SET_POLICY_SUMMARY = 'SET_POLICY_SUMMARY';

// Policy Retrieve
export const GET_LEGACY_POLICY = 'GET_LEGACY_POLICY';
export const GET_GW_POLICY = 'GET_GW_POLICY';

// Claim
export const SUBMIT_CLAIM = 'START_NEW_CLAIM';
export const SUBMIT_CLAIM_FAILED = 'SUBMIT_CLAIM_FAILED';
export const UPDATE_CLAIM = 'UPDATE_CLAIM';

// collect and store IPs
export const IPS = 'IPS';

// Summary
export const IS_LAST_STEPT = 'IS_LAST_STEPT';
export const DISABLED = 'DISABLED';
export const SHOW_TITLE = 'SHOW_TITLE';

// Claim Icons
export const waterDamage = require('../img/waterDamage_icon.png');
export const fireDamage = require('../img/fireDamage_icon.png');
export const weatherDamage = require('../img/weatherDamage_icon.png');
export const theftVandalism = require('../img/theftVandalism_icon.png');
export const other = require('../img/other_damage_icon.png');

export const FIELD_TYPE = {
  TEXT: 'text',
  TEXT_AREA: 'textArea',
  NUMBER: 'number',
  CURRENCY: 'currency',
  CHECKBOX: 'checkbox',
  SELECT: 'select',
  YES_NO: 'yesNo',
  EMAIL: 'email',
  TEL: 'tel',
  EMPTY: 'empty',
  LABEL: 'label',
  INFORMATION: 'information',
};

export const EMPTY_VALUE = {
  INPUT: '',
  SELECT: '',
  CHECKBOX: false,
  POS_NEG: '',
  NUMBER: 0,
};

export const DEPENDENCY_OPTIONS = {
  WEATHER: 'WT',
  FIRE: 'fire',
  VANDALISM: 'TV',
  WATER: 'WR',
  OTHER: 'allother_ext',
};

export const ComponentTitle = {
  DAMAGED: 'What was damaged?',
  CAUSED: 'What caused the damage?',
  REPORTER: 'Who is reporting the claim?',
  LOSSLOCATION: 'Which property is associated with this claim?',
  LOSSLOCATIONREC: 'Which vehicle is associated with this claim?',
  LOSSLOCATIONVESSEL: 'Which vessel is associated with this claim?',
  LOSSLOCATIONNOTPOLICY: 'Now tell us where the loss happened',
  DRIVER: 'Who was driving?',
  DAMAGEDR: 'Which best describes what happened?',
  INJURED: 'What caused the injury?',
};
export const MAX_LENGTH = 500;
export const MAX_LENGTH_20 = 20;
export const PLACEHOLDER_SELECT_VALUE = ' ';
export const CHECKBOX_ERROR_VALUE = 'checkboxError';

export const CompanyNumbers = [
  '030',
  '031',
  '033',
  '034',
  '036',
  '050',
  '070',
  '071',
  '076',
  '077',
  '078',
  '079',
  '080',
  '081',
  '085',
];

export const CustomReferrers = [
  { ref: 'np', display: 'Nat Pay' },
  { ref: 'a', display: 'amig.com' },
  { ref: 'an', display: 'Answer Net' },
];

export const StepTypes = {
  REPORTER: 'REPORTER',
  LOSS_LOCATION_NOT_POLICY: 'LOSS_LOCATION_NOT_POLICY',
  CAUSED: 'CAUSED',
  INJURED: 'INJURED',
  DAMAGED_REN: 'DAMAGED_REN',
  DAMAGED_REC: 'DAMAGED_REC',
  DAMAGED_RES: 'DAMAGED_RES',
  LOSS_LOCATION_REC: 'LOSS_LOCATION_REC',
  LOSS_LOCATION_RES: 'LOSS_LOCATION_RES',
};

export const ClaimSteps = {
  POLICY: 0,
  LOSS_LOCATION: 1,
  CLAIM_TYPE: 2,
  LOSS_DETAILS: 3,
  CLAIM_REPORTER: 4,
  CLAIM_SUMMARY: 5,
};

export const RenterSteps = {
  POLICY: 0,
  DAMAGED: 1,
  CAUSED: 2,
  REPORTER: 3,
  SUMMARY: 4,
};

export const ResidentialSteps = {
  POLICY: 0,
  LOSS_LOCATION: 1,
  DAMAGED: 2,
  CAUSED: 3,
  REPORTER: 4,
  SUMMARY: 5,
};

export const RecreationalSteps = {
  POLICY: 0,
  LOSS_LOCATION: 1,
  LOSS_LOCATION_NOT_POLICY: 2,
  DAMAGED: 3,
  REPORTER: 4,
  SUMMARY: 5,
};

export const NotValidatedRenterSteps = {
  LOSS_LOCATION_NOT_POLICY: 1,
  DAMAGED: 2,
  CAUSED: 3,
  REPORTER: 4,
  SUMMARY: 5,
};

export const NotValidatedResidentialSteps = {
  LOSS_LOCATION_NOT_POLICY: 1,
  DAMAGED: 2,
  CAUSED: 3,
  REPORTER: 4,
  SUMMARY: 5,
};

export const NotValidatedRecreationalSteps = {
  LOSS_LOCATION_NOT_POLICY: 1,
  DAMAGED: 2,
  REPORTER: 3,
  SUMMARY: 4,
};

export const EntityTypes = {
  CORPORATION: 'Corporation',
  INDIVIDUAL: 'Individual',
  JOIN_VENTURE: 'Joint Venture',
  LLC: 'LLC',
};

export const FormTypes = {
  AGENCY_ACCORD_REQUEST: 'agency_acord_request',
  AGENT_USER_LINKING_REQUEST: 'agent_user_linking_request',
  POLICY_NAME_CHANGE_REQUEST: 'policy_name_change_request',
  POLICY_ADDRESS_CHANGE_REQUEST: 'policy_address_change_request',
  POLICY_DOCUMENT_REQUEST: 'policy_document_request',
  POLICY_MAILING_ADDRESS_CHANGE_REQUEST:
    'policy_mailing_address_change_request',
};

export const PhoneTypes = {
  MOBILE: 'Mobile',
  HOME: 'Home',  
  WORK: 'Work',
};

export const MobileProviders = [
  'Verizon',
  'ATT',
  'T-Mobile',
  'Sprint',
  'U.S.Cellular',
  'Alltel',
  'Boost Mobile',
  'Virgin Mobile',
  'Republic Wireless',
  'Other',
];

export const WitnessLocations = {
  0: 'Inside vehicle',
  1: 'In the other vehicle',
  2: 'Pedestrian',
  3: 'Unknown',
};

export const LossParty = {
  INSURED: 'insured',
  THIRD_PARTY: 'third_party',
};

export const Layout = {
  MOBILE_PADDING: 0,
  DESKTOP_PADDING: 1,
  MOBILE_INTERIOR_PADDING: 1,
  DESKTOP_INTERIOR_PADDING: 4,
  MOBILE_GRID_OFFSET: 0,
  DESKTOP_GRID_OFFSET: 4,
};

export const FileUploadStatus = {
  PENDING: 0,
  SUCCESS: 10,
  FAILED: 20,
};

export const states = [
  {
    title: 'Alabama',
    abbreviation: 'AL',
  },
  {
    title: 'Alaska',
    abbreviation: 'AK',
  },
  {
    title: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    title: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    title: 'California',
    abbreviation: 'CA',
  },
  {
    title: 'Colorado',
    abbreviation: 'CO',
  },
  {
    title: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    title: 'Deleware',
    abbreviation: 'DE',
  },
  {
    title: 'Florida',
    abbreviation: 'FL',
  },
  {
    title: 'Georgia',
    abbreviation: 'GA',
  },
  {
    title: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    title: 'Idaho',
    abbreviation: 'ID',
  },
  {
    title: 'Illinois',
    abbreviation: 'IL',
  },
  {
    title: 'Indiana',
    abbreviation: 'IN',
  },
  {
    title: 'Iowa',
    abbreviation: 'IA',
  },
  {
    title: 'Kansas',
    abbreviation: 'KS',
  },
  {
    title: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    title: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    title: 'Maine',
    abbreviation: 'ME',
  },
  {
    title: 'Maryland',
    abbreviation: 'MD',
  },
  {
    title: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    title: 'Michigan',
    abbreviation: 'MI',
  },
  {
    title: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    title: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    title: 'Missouri',
    abbreviation: 'MO',
  },
  {
    title: 'Montana',
    abbreviation: 'MT',
  },
  {
    title: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    title: 'Nevada',
    abbreviation: 'NV',
  },
  {
    title: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    title: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    title: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    title: 'New York',
    abbreviation: 'NY',
  },
  {
    title: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    title: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    title: 'Ohio',
    abbreviation: 'OH',
  },
  {
    title: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    title: 'Oregon',
    abbreviation: 'OR',
  },
  {
    title: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    title: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    title: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    title: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    title: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    title: 'Texas',
    abbreviation: 'TX',
  },
  {
    title: 'Utah',
    abbreviation: 'UT',
  },
  {
    title: 'Vermont',
    abbreviation: 'VT',
  },
  {
    title: 'Virginia',
    abbreviation: 'VA',
  },
  {
    title: 'Washington',
    abbreviation: 'WA',
  },
  {
    title: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    title: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    title: 'Wyoming',
    abbreviation: 'WY',
  },
];

export const relationshipToInsured = [
  'Attorney',
  'Borrower',
  'Child',
  'Claimant',
  "Claimant's attorney",
  "Claimant's insurance co.",
  'Contractor',
  'Domestic partner',
  'Employee',
  'Friend',
  'Grandparent',
  'Insurance Company',
  'Parent',
  'Property Manager',
  'Public Adjuster',
  'Relative',
  'Rental representative',
  'Spouse',
  'Other',
];

export const InjuredTemplate = {
  severity: '',
  injuredPerson: {
    firstName: '',
    lastName: '',
    subtype: 'Person',
    primaryAddress: {
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      postalCode: '',
      country: 'US',
    },
    primaryPhoneType: 'mobile',
    cellNumber: '',
    emailAddress1: '',
    resideHousehold: null
  },
};

export const VehicleTemplate = {
  totalLoss: null,
  vehicleLossParty: 'third_party',
  vehicle: {
    make: 'unknown',
    model: 'unknown',
    year: 'unknown',
    VIN: 'unknown',
  },
  driver: {
    firstName: '',
    lastName: '',
    subtype: 'Person',
    primaryPhoneType: 'mobile',
    cellNumber: '',
  },
  missingItems: [],
};

export const DriverTemplate = {
  firstName: '',
  lastName: '',
  subtype: 'Person',
  primaryPhoneType: 'mobile',
  cellNumber: '',
};

export const IncidentOwnerTemplate = {
  firstName: 'unknown',
  lastName: 'unknown',
  subtype: 'Person',
  policyRole: 'incidentowner',
  primaryPhoneType: 'mobile',
  cellNumber: '999-999-9999',
  primaryAddress: {
    addressLine1: 'unknown',
    addressLine2: '',
    city: 'Cincinnati',
    state: 'OH',
    postalCode: '45201',
    country: 'US'
  }
};

export const POLICY_HOLDER = 'self';

export const relationshipPolicyHolder = [
  { value: POLICY_HOLDER, name: 'Policyholder' },
  { value: 'agent', name: 'Agent' },
  { value: 'attorney', name: 'Attorney' },
  { value: 'borrower_ext', name: 'Borrower' },
  { value: 'child', name: 'Child' },
  { value: 'claimant', name: 'Claimant' },
  { value: 'claimantatty', name: "Claimant's attorney" },
  { value: 'claimantinsco', name: "Claimant's insurance co." },
  { value: 'domesticpartner', name: 'Domestic partner' },
  { value: 'employee', name: 'Employee' },
  { value: 'friend', name: 'Friend' },
  { value: 'grandparent', name: 'Grandparent' },
  { value: 'insco', name: 'Insurance Company' },
  { value: 'parent', name: 'Parent' },  
  { value: 'propertymanager_ext', name: 'Property Manager' },
  { value: 'publicadjuster_ext', name: 'Public Adjuster' },
  { value: 'relative', name: 'Relative' },
  { value: 'rentalrep', name: 'Rental representative' },
  { value: 'spouse', name: 'Spouse' },
  { value: 'other', name: 'Other' },
];

export const RELATION_TO_POLICYHOLDER = {
  POLICY_HOLDER: 'Policyholder',
  agent: 'Agent',
  attorney: 'Attorney',
  borrower_ext: 'Borrower',
  child: 'Child',
  claimant: 'Claimant',
  claimantatty: `Claimant's attorney`,
  claimantinsco: `Claimant's insurance co.`,
  domesticpartner: 'Domestic partner',
  employee: 'Employee',
  friend: 'Friend',
  grandparent: 'Grandparent',
  insco: 'Insurance Company',
  parent: 'Parent',  
  propertymanager_ext: 'Property Manager',
  publicadjuster_ext: 'Public Adjuster',
  relative: 'Relative',
  rentalrep: 'Rental representative',
  spouse: 'Spouse',
  other: 'Other'
}

//Recreational
export const CAUSE_OPTIONS = {
  VEHICLEACCIDENT: 'VA',
  VEHICLEVANDALIZED: 'vandalismmischief_ext',
  VEHICLESTOLEN: 'theftentirevehiclevessel_ext',
  VEHICLEROADSIDE: 'towingroadsideassistance_ext',
  VEHICLEWEATHER: 'VW'
};

export const SOURCE_OPTIONS = {
  ANOTHERVEHICLE: 'vehiclesvesselscollision_ext',
  ROCK: 'glass_ext',
  FIXED_OBJECT: 'fixedobjcoll',
  AN_ANIMAL: 'animalinjurydamage_ext',
  WTHR_WIND: 'weatherwind_ext',
  WTHR_HAIL: 'weatherhail_ext',
  WTHR_FLDRISE: 'weatherfloodrisingwater_ext',
  WTHR_LIGHTNING: 'weatherlightning_ext',
  WTHR_WGT_ICE_SNOW: 'weatherweightofice_ext',
};

export const causeOfDamageVehicle = [
  {
    value: CAUSE_OPTIONS.VEHICLEACCIDENT,
    name: 'My vehicle was in an auto accident or collision',
  },
  {
    value: CAUSE_OPTIONS.VEHICLEVANDALIZED,
    name: 'My vehicle was vandalized or broken into',
  },
  { value: CAUSE_OPTIONS.VEHICLESTOLEN, name: 'My vehicle was stolen' },
  {
    value: CAUSE_OPTIONS.VEHICLEROADSIDE,
    name: 'My vehicle required a tow or roadside assistance',
  }, {
    value: CAUSE_OPTIONS.VEHICLEWEATHER,
    name: 'My vehicle was damaged due to weather',
  },
];

export const CAUSE_VESSEL = {
  VESSEL_ACCIDENT: 'VSA',
  VESSEL_VANDALIZED: 'vandalismmischief_ext',
  VESSEL_STOLEN: 'theftentirevehiclevessel_ext',
  VESSEL_ROADSIDE: 'towingroadsideassistance_ext',
  VESSEL_WEATHER: 'VSW',
};

export const causeDamVessel = [
  {
    value: CAUSE_VESSEL.VESSEL_ACCIDENT,
    name: 'My vessel was in an accident or collision',
  },
  {
    value: CAUSE_VESSEL.VESSEL_VANDALIZED,
    name: 'My vessel was vandalized or broken into',
  },
  { value: CAUSE_VESSEL.VESSEL_STOLEN, name: 'My vessel was stolen' },
  {
    value: CAUSE_VESSEL.VESSEL_ROADSIDE,
    name: 'My vessel required a tow or roadside assistance',
  },
  {
    value: CAUSE_VESSEL.VESSEL_WEATHER,
    name: 'My vessel was damaged due to weather',
  },
];

export const SOURCE_VESSEL = {
  ANOTHER_VESSEL: 'vehiclesvesselscollision_ext',
  ROCK: 'glass_ext',
  VESSEL_FIXED_OBJECT: 'fixedobjcoll',
  VESSEL_ANIMAL: 'animalinjurydamage_ext',
  WTHR_WIND: 'weatherwind_ext',
  WTHR_HAIL: 'weatherhail_ext',
  WTHR_FLDRISE: 'weatherfloodrisingwater_ext',
  WTHR_LIGHTNING: 'weatherlightning_ext',
  WTHR_WGT_ICE_SNOW: 'weatherweightofice_ext',
};

export const sourceDamVessel = [
  { value: SOURCE_VESSEL.ANOTHER_VESSEL, name: 'Another vessel' },
  {
    value: SOURCE_VESSEL.VESSEL_FIXED_OBJECT,
    name: 'A fixed object such as a stump',
  },
  { value: SOURCE_VESSEL.VESSEL_ANIMAL, name: 'An animal' },
  { value: SOURCE_VESSEL.ROCK, name: 'An object thrown from the water (rock)' },
  { value: 'fire', name: 'Fire/Smoke (not as a result of the accident)' },
];

export const vesselWeather = [
  { value: SOURCE_VESSEL.WTHR_WIND, name: 'Wind/Tornado/Hurricane' },
  { value: SOURCE_VESSEL.WTHR_HAIL, name: 'Hail' },
  { value: SOURCE_VESSEL.WTHR_FLDRISE, name: 'Flood/Rising Water' },
  { value: SOURCE_VESSEL.WTHR_LIGHTNING, name: 'Lightning' },
  { value: SOURCE_VESSEL.WTHR_WGT_ICE_SNOW, name: 'Weight of Ice/Snow' }, 
];

export const MISSING_ITEMS_OPTIONS = {
  PERSONAL_BELONGINGS: 'PERSONAL_BELONGINGS',
  VEHICLE_PARTS: 'VEHICLE_PARTS'
}

export const missingVessel = [
  { value: MISSING_ITEMS_OPTIONS.VEHICLE_PARTS, name: 'Vessel Parts' },
  { value: MISSING_ITEMS_OPTIONS.PERSONAL_BELONGINGS, name: 'Personal Belongings' },
];

export const vehicleAccident = [
  { value: SOURCE_OPTIONS.ANOTHERVEHICLE, name: 'Another vehicle' },
  {
    value: SOURCE_OPTIONS.FIXED_OBJECT,
    name: 'A fixed object such as a telephone pole or tree',
  },
  { value: SOURCE_OPTIONS.AN_ANIMAL, name: 'An animal' },
  { value: SOURCE_OPTIONS.ROCK, name: 'An object thrown from the road (rock)' },
  { value: 'fire', name: 'Fire/Smoke (not as a result of the accident)' },
];

export const vehicleWeather = [
  { value: SOURCE_OPTIONS.WTHR_WIND, name: 'Wind/Tornado/Hurricane' },
  { value: SOURCE_OPTIONS.WTHR_HAIL, name: 'Hail' },
  { value: SOURCE_OPTIONS.WTHR_FLDRISE, name: 'Flood/Rising Water' },
  { value: SOURCE_OPTIONS.WTHR_LIGHTNING, name: 'Lightning' },
  { value: SOURCE_OPTIONS.WTHR_WGT_ICE_SNOW, name: 'Weight of Ice/Snow' }, 
];

export const whatsMissing = [
  { value: MISSING_ITEMS_OPTIONS.VEHICLE_PARTS, name: 'Vehicle Parts' },
  { value: MISSING_ITEMS_OPTIONS.PERSONAL_BELONGINGS, name: 'Personal Belongings' },
];

export const DRIVING_OPTIONS = {
  DRIVINGINSURED: 'insured',
  DRIVINGOTHER: 'third_party',
};

export const driving = [
  { value: DRIVING_OPTIONS.DRIVINGINSURED, name: 'Insured' },
  { value: DRIVING_OPTIONS.DRIVINGOTHER, name: 'Other' },
];

export const anyOneInjuredOpt = [
  { value: 'yes', name: 'Yes' },
  { value: 'no', name: 'No' },
  { value: 'unknown', name: 'Unknown' },
];

//Residential
export const causeOfInjury = [
  { value: 'ST', name: 'Slip/Trip and Fall' },
  { value: 'AA', name: 'Assault/Altercation' },
  { value: 'AR', name: 'Animal Related' },
  { value: 'OT', name: 'Other' },
  { value: 'FS', name: 'Fire/Smoke' },
];

export const severeInjuries = [
  { value: 'hospitalstay_ext', name: 'Required hospitalization' },
  { value: 'seriousbrnheadinjury_ext', name: 'Serious head injury' },
  { value: 'minimal_inj_ext', name: 'Minimal Injury' },
  { value: 'noneabove_ext', name: 'None of the above' },
];

export const phoneRegExp = /[0-9]{3}-[0-9]{3}-[0-9]{4}/;
export const zipCodeExp = /^[0-9]{5}(?:-[0-9]{4})?$/;

export const causeOfDamage = [
  { value: DEPENDENCY_OPTIONS.WEATHER, name: 'Weather' },
  { value: DEPENDENCY_OPTIONS.FIRE, name: 'Fire/Smoke' },
  { value: DEPENDENCY_OPTIONS.VANDALISM, name: 'Theft/Vandalism' },
  { value: DEPENDENCY_OPTIONS.WATER, name: 'Water' },
  { value: DEPENDENCY_OPTIONS.OTHER, name: 'Other' },
];

export const weather = [
  { value: 'weatherwind_ext', name: 'Wind, Hurricane or a Tornado' },
  { value: 'weatherhail_ext', name: 'Hail' },
  { value: 'weatherlightning_ext', name: 'Lightning' },
  { value: 'weatherweightofice_ext', name: 'Ice/Snow' },
  { value: 'rainsleetnoexteriordamage_ext', name: 'Rain' },
  {
    value: 'weatherfloodrisingwater_ext',
    name: 'Weather related rising water',
  },
];

export const vandalism = [
  { value: 'theft_ext', name: 'Theft' },
  { value: 'vandalismmischief_ext', name: 'Vandalism' },
];

export const water = [
  {
    value: 'plumbingfailurewaterdamage_ext',
    name: 'Appliance or Plumbing Failure',
  },
  { value: 'backupsewersumppump_ext', name: 'Sump Pump Failure' },
  { value: 'rainsleetnoexteriordamage_ext', name: 'Roof Leaking' },
];

export const WaterDamageSources = [
  'Appliance',
  'Plumbing',
  'Freezing Pipes',
  'Roof',
  'Sump Pump/Sewer Backup',
];

export const ClaimTypes = {
  PROPERTY_DAMAGE: 'propertyDamage',
  THEFT_VANDALISM: 'theftVandalism',
  WATER_DAMAGE: 'waterDamage',
  FIRE_DAMAGE: 'fireDamage',
  RECREATIONAL_DAMAGE: 'vehicleDamage',
  OTHER: 'other',
};

export const PolicyTypes = {
  RENTERS: 'Renters',
  RECREATIONAL: 'Recreational',
  RESIDENTIAL: 'Residential',
  NOT_AUTHENTICATED_RENTERS: 'NotValidatedRenters',
  NOT_AUTHENTICATED_RECREATIONAL: 'NotValidatedRecreational',
  NOT_AUTHENTICATED_RESIDENTIAL: 'NotValidatedResidential',
};

export const vesselType = {
  boat: 'boat',
  yacht: 'yacht',
};

export const ProductCodes = {
  '001': 'recreational',
  '002': 'residential',
  '003': 'residential',
  '004': 'residential',
  '005': 'residential',
  '006': 'residential',
  '007': 'residential',
  '008': 'residential',
  '009': 'residential',
  '012': 'recreational',
  '014': 'recreational',
  '017': 'recreational',
  '023': 'residential',
  '024': 'residential',
  '025': 'recreational',
  300: 'recreational',
  400: 'residential',
  600: 'renters',
};

export const ClaimStatus = {
  STARTED: 0,
  API_SUBMISSION: 10,
  EMAIL_SUBMISSION: 20,
};

export const ClaimStatusLookup = {
  0: 'Started',
  10: 'API Submission',
  20: 'Email Submission',
};

export const ContactTypes = {
  POLICY_HOLDER: 'Policyholder',
  PRODUCER: 'Producer',
  SUBPRODUCER: 'SubProducer',
};

export const ContactMethods = {
  EMAIL: 'Email',
  MOBILE_PHONE: 'Mobile Phone',
  HOME_PHONE: 'Home Phone',
  WORK_PHONE: 'Work Phone',
};

export const WeatherDamage = [
  'Lightning',
  'Hail',
  'Weight from Snow/Ice',
  'Wind',
  'Other',
];

export const FaqList = [
  {
    q: 'Where can I find my policy number?',
    a: 'Your policy number can be found on your policy declarations page, on top of your bill, or on your proof of insurance card.',
  },
  {
    q: 'What is a policy number?',
    a: 'Your American Modern policy number uniquely identifies who you are and helps us find your policy coverages and details quickly.',
  },
  {
    q: 'What is the date and time of Loss?',
    a: 'It’s important for us to know when the loss took place so we can accurately service your claim. Please provide us the date and time the loss took place, if you are unsure of the exact time please estimate or let us know when you first noticed the loss.',
  },
  {
    q: 'What is a policy period?',
    a: 'This is the timeframe your policy provides coverage.',
  },
  {
    q: 'What is a limit?',
    a: 'This is the maximum amount of coverage you have for an individual loss. The limits vary by coverage and are based on your selections when the policy was purchased.',
  },
  {
    q: 'What is a deductible?',
    a: 'This is your share of the loss that was selected when the policy was purchased. This amount is subtracted from any amount paid on the loss.',
  },
  {
    q: 'What is the loss location?',
    a: 'It’s important for us to know where the loss took place so we can accurately service the claim. If your loss took place away from your residence, we will need additional information regarding the location.',
  },
  {
    q: 'What is a claims adjuster?',
    a: 'A claims adjuster is our company’s representative who supports you throughout the claim process. Your adjuster will reach out to you within 2-3 business days of submitting a claim. We recommend opting into text or email notifications where you will receive your adjuster’s contact information as soon as the claim is set up in our system.',
  },
];
