import { PLACEHOLDER_SELECT_VALUE, causeDamVessel, CAUSE_VESSEL, sourceDamVessel, SOURCE_VESSEL, EMPTY_VALUE, phoneRegExp, missingVessel, MAX_LENGTH_20, FIELD_TYPE, MAX_LENGTH, vesselWeather } from "../../../../../actions/types";
import * as Yup from 'yup';

export const damagedVessel = [
  {
    name: 'cause',
    label: 'Please pick the best that applies',
    initialValue: PLACEHOLDER_SELECT_VALUE,
    placeholder: 'Cause of damage',
    type: FIELD_TYPE.SELECT,
    InputProps:{style: {marginTop: '32px'}},
    options: causeDamVessel,
    isOneColumn: true,
    keyPathToSaveValue: 'claim.lossSource',
    yup: Yup.string().test(
      'causeTest',
      'Provide a value',
      value => value !== PLACEHOLDER_SELECT_VALUE,
    ),
  },
  {
    name: 'source',
    initialValue: PLACEHOLDER_SELECT_VALUE,
    placeholder: 'Source of damage',
    type: FIELD_TYPE.SELECT,
    toHide: true,
    dependencyFieldToShow: {name:'cause', value:[PLACEHOLDER_SELECT_VALUE, CAUSE_VESSEL.VESSEL_VANDALIZED, CAUSE_VESSEL.VESSEL_STOLEN, CAUSE_VESSEL.VESSEL_ROADSIDE]},
    dependencyField: 'cause',
    options: value => {return ((value === CAUSE_VESSEL.VESSEL_ACCIDENT && sourceDamVessel) ||
      (value === CAUSE_VESSEL.VESSEL_WEATHER && vesselWeather) ||  []);},
    isOneColumn: true,
    keyPathToSaveValue: 'claim.lossSource',
    yup: Yup.string().when('cause', {
      is: value => ((value === CAUSE_VESSEL.VESSEL_ACCIDENT) || 
                    (value === CAUSE_VESSEL.VESSEL_WEATHER)),
      then: Yup.string().test(
        'sourceTest',
        'Provide a value',
        value => value !== PLACEHOLDER_SELECT_VALUE,
      ),
    }),
  },
  {
    name: 'vehicleNumInvol',
    initialValue: EMPTY_VALUE.NUMBER,
    label: 'How many other vessels were involved?',
    type: FIELD_TYPE.NUMBER,
    inputProps: { min: 0,}, 
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [
      {
        name: 'cause', 
        value: CAUSE_VESSEL.VESSEL_ACCIDENT
      },
      {
        name: 'source', 
        value: [SOURCE_VESSEL.ANOTHER_VESSEL]
      }],
    keyPathToSaveValue: 'claim.eventDetails.vehicleIncidentCount',
    yup: Yup.number().when('source', {
      is: value => value === SOURCE_VESSEL.ANOTHER_VESSEL,
      then: schema => schema.min(1).nullable().required('Provide a value'),
    }),
  },
  {
    name: 'accidentDamaged',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Was the other vessel damaged?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [
      {
        name: 'cause', 
        value: CAUSE_VESSEL.VESSEL_ACCIDENT
      },
      {
        name: 'source', 
        value: [SOURCE_VESSEL.ANOTHER_VESSEL]
      },
      {
        name: 'vehicleNumInvol', 
        min: 0
      }],
      keyPathToSaveValue: 'claim.eventDetails.otherVehiclesDamaged',
    yup: Yup.mixed().when('vehicleNumInvol', {
      is: value => value > EMPTY_VALUE.NUMBER,
      then: schema => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'accidentInformation',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Do you have the other vessel information?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [
      {
        name: 'cause', 
        value: CAUSE_VESSEL.VESSEL_ACCIDENT
      },
      {
        name: 'source', 
        value: [SOURCE_VESSEL.ANOTHER_VESSEL]
      },
      {
        name: 'accidentDamaged', value: true
      },
      {
        name: 'vehicleNumInvol', 
        min: 0
      }],
    keyPathToSaveValue: 'claim.eventDetails.isVehicleParties',
    yup: Yup.mixed().when('accidentDamaged', {
      is: true,
      then: schema => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'rock',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Is damage limited to the windshield?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [{name: 'cause', value: [CAUSE_VESSEL.VESSEL_ACCIDENT, CAUSE_VESSEL.VESSEL_WEATHER]},{name: 'source', value: [SOURCE_VESSEL.WTHR_HAIL, SOURCE_VESSEL.ROCK]}],
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.windshieldDamageOnly',
    yup: Yup.mixed().when('source', {
      is: value => value === SOURCE_VESSEL.WTHR_HAIL || value === SOURCE_VESSEL.ROCK,
      then: schema => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'vandalized',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Is there any damage to the vessel?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: { name: 'cause', value: CAUSE_VESSEL.VESSEL_VANDALIZED },
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.vandalized',
    yup: Yup.mixed().when('cause', {
      is: value => value === CAUSE_VESSEL.VESSEL_VANDALIZED,
      then: schema => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'missing',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Is anything missing from the vessel?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: { name: 'cause', value: CAUSE_VESSEL.VESSEL_VANDALIZED },
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.missing',
    yup: Yup.mixed().when('cause', {
      is: value => value === CAUSE_VESSEL.VESSEL_VANDALIZED,
      then: schema => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'whatIsMissing',
    initialValue: PLACEHOLDER_SELECT_VALUE,
    placeholder: 'What is missing?',
    type: FIELD_TYPE.SELECT,
    options: missingVessel,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [{name: 'cause', value: CAUSE_VESSEL.VESSEL_VANDALIZED},{name: 'missing', value: true}],
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.whatIsMissing',
    yup: Yup.mixed().when('missing', {
      is: true,
      then: Yup.string().test(
        'sourceTest',
        'Provide a value',
        value => value !== PLACEHOLDER_SELECT_VALUE,
      ),
    }),
  },
  {
    name: 'beenRecovered',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Has the vessel been recovered?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: { name: 'cause', value: CAUSE_VESSEL.VESSEL_STOLEN },
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.beenRecovered',
    yup: Yup.mixed().when('cause', {
      is: value => value === CAUSE_VESSEL.VESSEL_STOLEN,
      then: schema => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'vesselMissing',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'How long has the vessel been missing?',
    placeholder: 'Describe',
    type: FIELD_TYPE.TEXT_AREA,
    isCountCharacters: true,
    maxLength: MAX_LENGTH_20,
    inputProps: { maxLength: MAX_LENGTH_20 },
    toHide: true,
    dependencyFieldToShow: [{name: 'cause', value: CAUSE_VESSEL.VESSEL_STOLEN},{name: 'beenRecovered', value: false}],
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.missingHowLong',
    yup: Yup.mixed().when('beenRecovered', {
      is: value => value === false,
      then: schema => schema.required('Provide a value'),
    }),
  },
  {
    name: 'towCompleted',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Has the tow already been completed?',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: { name: 'cause', value: CAUSE_VESSEL.VESSEL_ROADSIDE },
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.towCompleted',
    yup: Yup.mixed().when('cause', {
      is: value => value === CAUSE_VESSEL.VESSEL_ROADSIDE,
      then: schema => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'towPay',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Did you pay for the tow out of pocket? ',
    type: FIELD_TYPE.YES_NO,
    isOneColumn: true,
    toHide: true,
    dependencyFieldToShow: [{name: 'cause', value: CAUSE_VESSEL.VESSEL_ROADSIDE},{name: 'towCompleted', value: true}],
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.towAmount',
    yup: Yup.mixed().when('towCompleted', {
      is: value => value === true,
      then: schema => schema.required('Select one').nullable(),
    }),
  },
  {
    name: 'howMuch',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'How much was the tow?',
    type: FIELD_TYPE.CURRENCY,
    currency: '$',
    toHide: true,
    isOneColumn: true,
    dependencyFieldToShow: [{name: 'cause', value: CAUSE_VESSEL.VESSEL_ROADSIDE},{name: 'towCompleted', value: true},{name: 'towPay', value: true}],
    keyPathToSaveValue: 'claim.eventDetails.driverVehicleDetails.towAmountPaid',
    yup: Yup.string().when('towPay', {
      is: true,
      then: schema => schema.required('Provide a value'),
    }),
  },
  {
    name: 'detail',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Tell us what happened',
    placeholder: 'Describe property damage',
    type: FIELD_TYPE.TEXT_AREA,
    isCountCharacters: true,
    helperText: `${MAX_LENGTH} characters remaining`,
    inputProps: { maxLength: MAX_LENGTH },
    keyPathToSaveValue: 'claim.description',
    yup: Yup.string().required('Provide a value'),
  },
  {
    name: 'policeOrFireInvolved',
    initialValue: EMPTY_VALUE.POS_NEG,
    label: 'Were the police or fire department involved?',
    type: FIELD_TYPE.YES_NO,
    keyPathToSaveValue: 'claim.eventDetails.policeOrFireInvolved',
    yup: Yup.boolean().required('Select one').nullable(),
  },
  {
    name: 'departmentName',
    initialValue: EMPTY_VALUE.INPUT,
    label: 'Department name',
    type: FIELD_TYPE.TEXT,
    toHide: true,
    dependencyFieldToShow: {name:'policeOrFireInvolved', value: true},
    keyPathToSaveValue: 'claim.officials[0].reportingAgency',
    yup: Yup.string().when('policeOrFireInvolved', {
      is: true,
      then: schema => schema.required('Provide a value'),
    }),
  }
];