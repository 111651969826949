import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import OffsetGrid from '../layout/OffsetGrid';
import ClaimInfoComponent from './ClaimInfoComponent';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import PropTypes from 'prop-types';
import { getClaimsTrackingSubmission } from '../../actions/claimActions';
import { retrieveAttachments } from '../../actions/attachments';
import { getDeviceTypeInfo } from 'responsive-react/dist/utilResponsive';
import AppMenu from '../layout/AppMenu';

// MSAL
import { MsalAuthenticationTemplate, useAccount, useMsal } from '@azure/msal-react';
import { InteractionStatus, InteractionType } from '@azure/msal-browser';
import { loginRequest } from '../../auth/authConfig';
import { ErrorComponent } from '../ui/ErrorComponent';
import { LoadingComponent } from '../ui/LoadingComponent';


const ClaimSummaryFnolComponent = ({
  claim,
  getClaimsTrackingSubmission,
  retrieveAttachments,
  authRequired = false,
}) => {
  const { deviceType } = getDeviceTypeInfo();
  const qs = useLocation().search;
  const submissionId = qs.split('?')[1].split('=')[1];


  const { instance, accounts, inProgress } = useMsal();
  
  const authRequest = {
    ...loginRequest,
  };

  useEffect(() => {
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
      instance.setActiveAccount(accounts[0]);
    }
  }, [inProgress, accounts, instance])

  useEffect(() => {
    const getClaimSubmission = async (submissionId) => {
      if (submissionId) {
        await getClaimsTrackingSubmission(submissionId);
      }
    };
    getClaimSubmission(submissionId);
  }, []);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}>
      <AppMenu user={accounts.length > 0 ? accounts[0].name : ''} />
      <OffsetGrid offset={2}>
      <Box pt={12} />
        <Grid container spacing={1}>
          <Grid item xs={3} sm={4} style={{ textAlign: 'right' }}>
            <img
              // className={classes.logo}
              alt='American Modern Logo'
              src='https://blog.amig.com/wp-content/themes/amig2015/assets/images/AMIG-Logo_272x76.png'
            />
          </Grid>
          <Grid item xs={9} sm={8}>
            <Typography variant='h1' align='left' color='primary'>
              Online Claim Submission
            </Typography>
            <Typography
              variant='h5'
              style={{ color: '#666' }}
              align='left'
              gutterBottom>
              {`Claim Confirmation Id: ${claim.confirmationId}`}
            </Typography>
          </Grid>
        </Grid>
      </OffsetGrid>
      <Container style={{ background: '#ededed' }} align='center'>
        <Box p={3}>
          <Paper elevation={1}>
            <Box p={3}>
              <ClaimInfoComponent submissionId={submissionId} />
            </Box>
          </Paper>
        </Box>
      </Container>
    </MsalAuthenticationTemplate>
  );
};
const mapStateToProps = (state) => ({
  claim: state.claim
});

ClaimSummaryFnolComponent.propTypes = {
  claim: PropTypes.object.isRequired,
  retrieveAttachments: PropTypes.func.isRequired,
};
export default connect(mapStateToProps, {
  getClaimsTrackingSubmission,
  retrieveAttachments,
})(ClaimSummaryFnolComponent);
